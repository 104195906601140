
import { Component, Prop, Vue } from 'vue-property-decorator'
import lsPagination from '@/components/ls-pagination.vue'
import { RequestPaging } from '@/utils/util'
import { apiUserSearchList, apiUserSearchShopList, apiUserList } from '@/api/admin'

@Component({
  components: {
    lsPagination
  }
})
export default class AdminUserList extends Vue {
  /** S Data **/
  pager: RequestPaging = new RequestPaging()
  pickerOptions = {
    shortcuts: [
      {
        text: '最近一周',
        onClick (picker: any) {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
          picker.$emit('pick', [start, end])
        }
      },
      {
        text: '最近一个月',
        onClick (picker: any) {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
          picker.$emit('pick', [start, end])
        }
      },
      {
        text: '最近三个月',
        onClick (picker: any) {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
          picker.$emit('pick', [start, end])
        }
      }
    ]
  }

  form = {
    sid: '', // 商户ID
    keyword: '', // 用户信息
    level: '', // 用户等级
    label_id: '', // 用户标签
    min_amount: '', // 消费最小金额
    max_amount: '', // 消费最大金额
    source: '', // 注册来源
    create_start_time: 0, // 注册开始时间（传时间戳）
    create_end_time: 0, // 注册结算时间（传时间戳）
    disable: '' // 会员状态
  }

  // 用户选择框数据
  platformShopList = {}
  userLevelList = {}
  userLabelList = {}
  sourceList = {}
  // 日期选择器数据
  timeForm = []
  /** E Data **/

  /** S Methods **/
  // 拆分日期选择器时间
  splitTime () {
    if (this.timeForm != null) {
      this.form.create_start_time = new Date(this.timeForm[0]).getTime() / 1000
      this.form.create_end_time = new Date(this.timeForm[1]).getTime() / 1000
    } else {
      this.form.create_start_time = 0
      this.form.create_end_time = 0
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  onReset () {
    this.form = {
      sid: '', // 商户ID
      keyword: '', // 用户信息
      level: '', // 用户等级
      label_id: '', // 用户标签
      min_amount: '', // 消费最小金额
      max_amount: '', // 消费最大金额
      source: '', // 注册来源
      create_start_time: 0, // 注册开始时间（传时间戳）
      create_end_time: 0, // 注册结算时间（传时间戳）
      disable: '' // 会员状态
    }
    this.timeForm = []
    this.getUserList()
  }

  query () {
    this.pager.page = 1
    if (this.form.min_amount && this.form.max_amount) {
      if (this.form.min_amount >= this.form.max_amount) {
        return this.$message.error('消费金额最低价应小于最高价')
      }
    }
    this.getUserList()
  }

  // 获取用户数据列表
  getUserList (): void {
    this.pager.request({
      callback: apiUserList,
      params: {
        ...this.form
      }
    })
  }

  platformShopChange (sid = 0) {
    this.form.level = ''
    this.form.label_id = ''
    if (sid > 0) {
      this.getUserSearchList(sid)
    } else {
      this.userLevelList = []
      this.userLabelList = []
    }
  }

  getUserSearchList (sid = 0) {
    apiUserSearchList({ sid: sid }).then(res => {
      this.userLevelList = res.user_level_list
      this.userLabelList = res.user_label_list
    })
  }

  getUserSearchShopList () {
    apiUserSearchShopList().then(res => {
      this.sourceList = res.source_list
      this.platformShopList = res.platform_shop_list
    })
  }

  /** E Methods **/
  /** S ods **/
  created () {
    this.getUserSearchShopList()
    this.getUserList()
  }

  showEquity (mobile: any) {
    console.log(mobile)
    this.$router.push({
      path: '/admin/user/equity_list',
      query: {
        mobile: mobile
      }
    })
  }
}
